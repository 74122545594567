import {
  Background, Factor, Possibility, Score, TimeRange,
} from '@/core/interfaces/common';
import { Comment } from '@/core/interfaces/logs';

import { request } from '@/utils/request';

import {
  CommentPayload,
  PatchRiskPayload,
  PostCommentPayload,
  PostCommentReplyPayload,
  PostRiskPayload,
  PutRiskContextPayload,
  Risk,
} from './interfaces/risk';

export const api = {
  getCommentsList: ({
    project, log,
  }: CommentPayload) => request.get<Array<Comment>>(`v1/projects/${project}/risk_logs/${log}/comments/`),
  getRiskBackgroundsList: () => request.get<Array<Background>>('v1/risk_backgrounds/'),
  getRiskFactorsList: () => request.get<Array<Factor>>('v1/risk_factors/'),
  getRiskPossibilitiesList: () => request.get<Array<Possibility>>('v1/risk_possibilities/'),
  getRiskScoresList: () => request.get<Array<Score>>('v1/risk_scores/'),
  getRiskTimeRangesList: () => request.get<Array<TimeRange>>('v1/risk_time_ranges/'),
  getRisks: (id: number) => request.get<Array<Risk>>(`v1/projects/${id}/risk_logs/?is_archived=false`),
  getRisksArchived: (id: number) => request.get<Array<Risk>>(`v1/projects/${id}/risk_logs/?is_archived=true`),
  patchRisk: ({
    id, data,
  }: PatchRiskPayload) => request.patch<Risk>(`v1/projects/${data.project}/risk_logs/${id}/`, data),
  postRisk: (data: PostRiskPayload) => request.post<Risk>(`v1/projects/${data.project}/risk_logs/`, data),
  postRiskComment: ({
    data,
    log,
    project,
  }: PostCommentPayload) => request.post<Pick<Comment, 'body' | 'createdBy'>>(`v1/projects/${project}/risk_logs/${log}/comments/`, data),
  postRiskCommentReply: ({
    comment,
    data,
    log,
    project,
  }: PostCommentReplyPayload) => request.post<Pick<Comment, 'body' | 'createdBy'>>(`v1/projects/${project}/risk_logs/${log}/comments/${comment}/comments/`, data),
  putRiskContext: ({
    projectId,
    riskId,
    historyId,
    context,
  }: PutRiskContextPayload) => request.put<Pick<Risk, 'context'>>(`v1/projects/${projectId}/risk_logs/${riskId}/history/${historyId}/`, { context }),
};
