import { Currencies } from '@/utils/helpers';

export type Nullable<T = undefined> = T | null | undefined;

export type NullableKeys<T = undefined> = {
  [P in keyof T]: Nullable<T[P]>;
};

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum ActiveDrawerOption {
  PM,
  ALL_PROJECTS,
  OBSERVED_PROJECTS,
}

export type WithId = {
  id: number;
};

export type SimpleDetails = {
  archived?: boolean;
  name: string;
  category?: number;
  isObservedByUser: boolean;
} & WithId;

export type PaginatedResponse<T> = {
  results: Array<T>;
  next: Nullable<string>;
  prev: Nullable<string>;
  count: number;
};

export interface PaginationParams {
  ordering?: string;
  page: number;
  pageSize: number;
}

export enum score {
  STABLE,
  IN_DANGER,
  NOT_STABLE,
}

export enum scoreNames {
  STABLE = 'Stable',
  IN_DANGER = 'Not stable',
  NOT_STABLE = 'In danger',
}
export interface FireScore extends WithId {
  scoreName: string;
  riskScore: score;
}

export interface HistoryFireScore extends WithId {
  historyDate: string;
  fireScore: FireScore;
}
export interface Currency extends SimpleDetails {
  shortName: keyof typeof Currencies;
  symbol: string;
}

export interface Score extends SimpleDetails {
  score: score;
}

export type Background = SimpleDetails;
export type Factor = SimpleDetails;
export type Possibility = SimpleDetails;
export type TimeRange = SimpleDetails;
export type Bio = SimpleDetails;
export type Industry = SimpleDetails;
export type ProcessOwnership = SimpleDetails;

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface ServerChoice {
  value: string;
  displayName: string;
}

export interface SortPayload {
  ordering?: string;
  search?: string;
  showOnlyObserved?: boolean;
}

export type InputVariant = 'outlined' | 'standard' | 'filled' | undefined;
