import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { RequestStatus } from '@/core/interfaces/common';

import {
  archivedOpportunityAdapter,
  backgroundAdapter,
  factorsAdapter,
  opportunityAdapter,
  possibilitiesAdapter,
  scoresAdapter,
  timeRangeAdapter,
} from './adapters';
import { OpportunityState } from '../interfaces';

export const {
  selectAll: selectAllOpportunities,
} =
  opportunityAdapter.getSelectors<RootState>(state => state.opportunity.list);

export const {
  selectAll: selectAllArchivedOpportunities,
} =
  archivedOpportunityAdapter.getSelectors<RootState>(state => state.opportunity.archivedList);

export const {
  selectAll: selectAllBackgrounds,
} = backgroundAdapter.getSelectors<RootState>(state => state.opportunity.backgrounds);

export const {
  selectAll: selectAllFactors,
} = factorsAdapter.getSelectors<RootState>(state => state.opportunity.factors);

export const {
  selectAll: selectAllPossibilities,
} = possibilitiesAdapter.getSelectors<RootState>(state => state.opportunity.possibilities);

export const {
  selectAll: selectAllScores,
} = scoresAdapter.getSelectors<RootState>(state => state.opportunity.scores);
export const {
  selectAll: selectAllTimeRanges,
} = timeRangeAdapter.getSelectors<RootState>(state => state.opportunity.timeRanges);

const featureStateSelector = (state: RootState): OpportunityState => state.opportunity;
const commentsStateSelector = (state: RootState, id: number) => state.opportunity.comments[id];

export const getOpportunitySavingStatus = createSelector(
  featureStateSelector,
  state => state.list.isSaving
);

export const getOpportunitiesListStatus = createSelector(
  featureStateSelector,
  state => state.list.status
);

export const getArchivedOpportunitiesListStatus = createSelector(
  featureStateSelector,
  state => state.archivedList.status
);

export const getOpportunityComments = createSelector(
  commentsStateSelector,
  state => state?.data ?? []
);

export const getOpportunityCommentsStatus = createSelector(
  commentsStateSelector,
  state => state?.status ?? RequestStatus.IDLE
);
