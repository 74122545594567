import {
  Action,
  combineReducers,
  Reducer,
} from 'redux';
import reduceReducers from 'reduce-reducers';

import { hydrateReducer } from '@/core/store/reducers/hydrate';
import { RootState } from '@/core/interfaces/store';

import { authReducer } from '@/features/Auth/store';
import { configReducer } from '@/features/Config/store';
import { projectManagerReducer } from '@/features/ProjectManager/store';
import { projectReducer } from '@/features/Project/store';
import { mentionReducer } from '@/features/Mention/store';
import { notificationReducer } from '@/features/Notification/store';
import { globalMessageReducer } from '@/features/GlobalMessage/store';
import { integrationReducer } from '@/features/Integration/store';
import { riskReducer } from '@/features/Risk/store';
import { employeeReducer } from '@/features/Employee/store';
import { opportunityReducer } from '@/features/Opportunity/store';
import { dashboardReducer } from '@/features/Dashboard/store';

const featuresReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  dashboard: dashboardReducer,
  employee: employeeReducer,
  globalMessage: globalMessageReducer,
  integration: integrationReducer,
  mention: mentionReducer,
  notification: notificationReducer,
  opportunity: opportunityReducer,
  project: projectReducer,
  projectManager: projectManagerReducer,
  risk: riskReducer,
});

export const rootReducer = reduceReducers<RootState>(
  hydrateReducer,
  featuresReducer
) as Reducer<RootState, Action>;

