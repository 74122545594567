import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import {
  JWTPayload,
  Token,
} from '@/features/Auth/interfaces/auth';

export function tokenLifetime(token: string) {
  const { exp } = jwtDecode<JWTPayload>(token);

  return exp - Math.floor(Date.now() / 1000);
}

export function isTokenExpired(token: string) {
  if (!token) return true;

  const { exp } = jwtDecode<JWTPayload>(token);

  return exp - Math.floor(Date.now() / 1000) <= 0;
}

export function isTokenCloseToExpiry(token: string) {
  if (!token) return false;

  const { exp } = jwtDecode<JWTPayload>(token);

  return (exp - Math.floor(Date.now() / 1000)) < (2 * 60) && !!Cookies.get('access');
}

export function setToken(
  type: Token,
  token: string,
  expiration: number | undefined
) {
  Cookies.set(type, token, expiration ? { expires: new Date(expiration * 1000) } : {});
}

export function clearToken(type: Token) {
  Cookies.remove(type);
}

export function getToken(type: Token) {
  return Cookies.get(type);
}
