import { EntityId } from '@reduxjs/toolkit';

export enum GlobalMessageType {
  INFO,
  WARN,
  ERROR,
  SUCCESS,
}

export interface GlobalMessage {
  message: string;
  id: EntityId;
  type: GlobalMessageType;
}
