import {
  createAction, EntityId, nanoid,
} from '@reduxjs/toolkit';

import { getActionPrefix } from '@/utils/helpers';

import { AddGlobalMessagePayload } from '../interfaces';

const actionPrefix = getActionPrefix('globalMessage');

export const addGlobalMessage = createAction(`${actionPrefix}/add`, (payload: AddGlobalMessagePayload) => ({
  payload: {
    id: nanoid(),
    ...payload,
  },
}));

export const removeGlobalMessage = createAction<EntityId>(`${actionPrefix}/remove`);
export const clearGlobalMessages = createAction(`${actionPrefix}/clear`);

