import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  Bio,
  Currency,
  FireScore,
  HistoryFireScore,
  Industry,
  ProcessOwnership,
  ServerChoice,
  SimpleDetails,
} from '@/core/interfaces/common';

import {
  HistoryContext,
  NpsScoring,
  ProjectMonitor,
} from '../interfaces';

export const projectBioAdapter = createEntityAdapter<Bio>();
export const projectClientTypeAdapter = createEntityAdapter<Industry>();
export const projectDesignAdapter = createEntityAdapter<Industry>();
export const projectNpsHistoryAdapter = createEntityAdapter<NpsScoring>();
export const projectProcessOwnershipsAdapter = createEntityAdapter<ProcessOwnership>();
export const projectNameAdapter = createEntityAdapter<SimpleDetails>();
export const projectMonitorAdapter = createEntityAdapter<ProjectMonitor>();
export const projectFirescoreHistoryAdapter = createEntityAdapter<HistoryFireScore>({
  selectId: firescore => firescore.historyDate,
  sortComparer: (a, b) => b.historyDate.localeCompare(a.historyDate),
});
export const projectOwnershipAdapter = createEntityAdapter<Industry>();
export const projectMethodologyAdapter = createEntityAdapter<Industry>();
export const projectFirescoreAdapter = createEntityAdapter<FireScore>();
export const projectCountryAdapter = createEntityAdapter<ServerChoice>({
  selectId: element => element.value,
});
export const projectCurrencyAdapter = createEntityAdapter<Currency>();
export const projectIndustryAdapter = createEntityAdapter<Industry>();
export const projectTypeAdapter = createEntityAdapter<Industry>();
export const historyContextAdapter = createEntityAdapter<HistoryContext>();
export const historyClientFeedbackAdapter = createEntityAdapter<HistoryContext>();
