import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import {
  employeeAdapter, roleAdapter,
} from './adapters';
import { EmployeeState } from '../interfaces';

export const {
  selectAll: selectAllEmployees,
  selectEntities: selectAllEmployeeEntities,
} = employeeAdapter.getSelectors<RootState>(state => state.employee.list);

export const {
  selectAll: selectAllRoles,
  selectEntities: selectAllRoleEntities,
} = roleAdapter.getSelectors<RootState>(state => state.employee.roles);

const featureStateSelector = (state: RootState): EmployeeState => state.employee;

export const getEmployeesListStatus = createSelector(
  featureStateSelector,
  state => state.list.status
);
