import {
  ActionReducerMapBuilder,
  createReducer,
  isAnyOf,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import { AuthState } from '../interfaces/state';
import {
  getUser,
  login,
  logout,
} from './actions';

const initialState: AuthState = {
  hydrateUserStatus: RequestStatus.IDLE,
  isLogging: false,
  user: {
    email: null,
    id: null,
  },
};

export const authReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<AuthState>) => {
    builder
      .addCase(getUser.pending, state => {
        state.hydrateUserStatus = RequestStatus.LOADING;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.hydrateUserStatus = RequestStatus.SUCCEEDED;
        state.user = payload;
      })
      .addCase(getUser.rejected, state => {
        state.hydrateUserStatus = RequestStatus.FAILED;
      })
      .addCase(logout.fulfilled, state => {
        state.user = initialState.user;
      })
      .addCase(login.pending, state => {
        state.isLogging = true;
      })
      .addMatcher(isAnyOf(login.fulfilled, login.rejected), state => {
        state.isLogging = false;
      });
  }
);
