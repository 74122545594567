import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';

import { getActionPrefix } from '@/utils/helpers';

import { api } from '../api';
import {
  Role, SimpleEmployee,
} from '../interfaces';

const actionPrefix = getActionPrefix('employee');

export const getEmployees = createAsyncThunk<
  Array<SimpleEmployee>,
  void
>(`${actionPrefix}/get-list`, async () => {
  const { data } = await api.getEmployeesList();

  return data;
});

export const clearEmployees = createAction(`${actionPrefix}/clear-list`);

export const getRoles = createAsyncThunk<
  Array<Role>,
  void
>(`${actionPrefix}/get-roles`, async () => {
  const { data } = await api.getRolesList();

  return data;
});
