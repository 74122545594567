import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';
import { RequestStatus } from '@/core/interfaces/common';

import {
  backgroundAdapter,
  factorsAdapter,
  possibilitiesAdapter,
  riskAdapter,
  riskArchivedAdapter,
  scoresAdapter,
  timeRangeAdapter,
} from './adapters';
import { RiskState } from '../interfaces';

export const {
  selectAll: selectAllRisks,
} =
  riskAdapter.getSelectors<RootState>(state => state.risk.list);

export const {
  selectAll: selectAllArchivedRisks,
} = riskArchivedAdapter.getSelectors<RootState>(state => state.risk.listArchived);

export const {
  selectAll: selectAllBackgrounds,
} = backgroundAdapter.getSelectors<RootState>(state => state.risk.backgrounds);

export const {
  selectAll: selectAllFactors,
} = factorsAdapter.getSelectors<RootState>(state => state.risk.factors);

export const {
  selectAll: selectAllPossibilities,
} = possibilitiesAdapter.getSelectors<RootState>(state => state.risk.possibilities);

export const {
  selectAll: selectAllScores,
} = scoresAdapter.getSelectors<RootState>(state => state.risk.scores);
export const {
  selectAll: selectAllTimeRanges,
} = timeRangeAdapter.getSelectors<RootState>(state => state.risk.timeRanges);

const featureStateSelector = (state: RootState): RiskState => state.risk;
const commentsStateSelector = (state: RootState, id: number) => state.risk.comments[id];

export const getRiskSavingStatus = createSelector(
  featureStateSelector,
  state => state.list.isSaving
);

export const getRisksListStatus = createSelector(
  featureStateSelector,
  state => state.list.status
);

export const getArchivedRisksListStatus = createSelector(
  featureStateSelector,
  state => state.listArchived.status
);

export const getRiskComments = createSelector(
  commentsStateSelector,
  state => state?.data ?? []
);

export const getRiskCommentsStatus = createSelector(
  commentsStateSelector,
  state => state?.status ?? RequestStatus.IDLE
);
