import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import { ProjectManagerState } from '@/features/ProjectManager/interfaces';

import {
  projectManagersAdapter, projectManagersProjectAdapter,
} from './adapters';

const featureStateSelector = (state: RootState): ProjectManagerState => state.projectManager;

export const {
  selectAll: selectAllProjectManagers,
  selectById: selectProjectManagerById,
} =
  projectManagersAdapter.getSelectors<RootState>(state => state.projectManager.list);

export const {
  selectAll: selectAllProjectManagersProjects,
} =
  projectManagersProjectAdapter.getSelectors<RootState>(state => state.projectManager.projects);

export const getProjectManagersListStatusSelector = createSelector(
  featureStateSelector,
  state => state.list.status
);

export const getActiveProjectManager = createSelector(
  featureStateSelector,
  state => state.list.active
);

export const getProjectManagersProjectsStatusSelector = createSelector(
  featureStateSelector,
  state => state.projects.status
);
