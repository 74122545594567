import { ServerResponse } from 'http';
import Router from 'next/router';
import sanitize from 'xss';
import { decode } from 'html-entities';

import { FeatureNames } from '@/core/interfaces/store';
import {
  Nullable,
} from '@/core/interfaces/common';

export const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const redirect = (res: Nullable<ServerResponse>, route: string) => {
  if (!isBrowser && res) {
    res.writeHead(307, {
      Location: route,
    });
    res.end();
  } else {
    Router.replace(route);
  }
};

export const getActionPrefix = (featureName: FeatureNames) => featureName.toUpperCase();

export const mapToOptions = <T, K extends keyof T, L>(
  options: Array<T>,
  label: K,
  value: K,
  prefix?: L
) => options.map(option => ({
    label: `${prefix ?? ''}${option[label]}`,
    value: option[value],
  }));

export const emailValidationRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  /* eslint-disable sort-keys */
  return result ?
    `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})` :
    null;
};

export const formHasEmptyFields =
  (fields: Array<any>, values: { [key: string]: any }) => fields
    .reduce((arr: Array<boolean>, current: string) => {
      const value = values[current];

      arr.push(
        Array.isArray(value) ?
          (
            value.length &&
            value.map(el => Boolean(el.name ?? el.employee))
          ) || false :
          value
      );

      return arr.flat(2);
    }, []).some((el: boolean) => !el);

export const getStringWithoutHTMLTags = (code: string | null | undefined) => (typeof code === 'string' ? code.replace(/<[^>]+>/g, '') : '');

export const getSafeHTML = (code: string | null | undefined) => sanitize(decode(code), {
  // whitelist of tags matches those that can be
  // generated by RichTextField core component (quill)
  whiteList: {
    a: [
      'href',
      'rel',
      'target',
    ],
    h1: [],
    h2: [],
    h3: [],
    p: [],
    u: [],
    ol: [],
    ul: [],
    li: [],
    strong: [],
    em: [],
    br: [],
  },
});

type ObjectType = { [key: string]: unknown };

export const findObjectDiff = <T extends ObjectType>(
  object: T,
  comparedObject: T
): Partial<T> => {
  const data = Object.entries(object).filter(([key, value]) => comparedObject[key] !== value)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value,

    }), {});

  return data;
};

export enum Currencies {
  USD = '$',
  PLN = 'zł',
  EUR = '€',
  GBP = '£',
}

export const isEmpty = (value: unknown) => !(!!value || value === 0);
