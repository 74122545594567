import {
  ActionReducerMapBuilder,
  createReducer,
} from '@reduxjs/toolkit';

import { GlobalMessageState } from '../interfaces/state';
import {
  addGlobalMessage,
  clearGlobalMessages,
  removeGlobalMessage,
} from './actions';
import { globalMessageAdapter } from './adapters';

const initialState: GlobalMessageState = globalMessageAdapter.getInitialState();

export const globalMessageReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<GlobalMessageState>) => {
    builder
      .addCase(addGlobalMessage, (state, { payload }) => {
        globalMessageAdapter.addOne(state, payload);
      })
      .addCase(removeGlobalMessage, (state, { payload }) => {
        globalMessageAdapter.removeOne(state, payload);
      })
      .addCase(clearGlobalMessages, state => {
        globalMessageAdapter.removeAll(state);
      });
  }
);
