import jwtDecode from 'jwt-decode';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ThunkHandlers,
  ValidationError,
} from '@/core/interfaces/store';

import {
  AuthUser,
  GoogleRequestPayload,
  JWTPayload,
  LoginRequestData,
  TokenTypes,
} from '@/features/Auth/interfaces';
import { api } from '@/features/Auth/api';

import { request } from '@/utils/request';
import {
  clearToken,
  setToken,
} from '@/utils/jwt';
import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('auth');

export const getUser = createAsyncThunk<
  AuthUser,
  void
>(`${actionPrefix}/get-user`, async () => {
  const { data } = await api.getUser();

  return data;
});

export const logout = createAsyncThunk(
  `${actionPrefix}/logout`,
  async () => {
    clearToken(TokenTypes.ACCESS);
    clearToken(TokenTypes.REFRESH);

    request.removeAuthorizationToken();
  }
);

export const login = createAsyncThunk<
  true | ValidationError,
  LoginRequestData,
  ThunkHandlers
>(
  `${actionPrefix}/login`,
  async (credentials: LoginRequestData, {
    dispatch,
    rejectWithValue,
  }) => {
    try {
      const {
        data: {
          access,
          refresh,
        },
      } = await api.login(credentials);

      const { exp: accessExpiration } = jwtDecode<JWTPayload>(access);
      const { exp: refreshExpiration } = jwtDecode<JWTPayload>(refresh);

      setToken(TokenTypes.ACCESS, access, accessExpiration);
      setToken(TokenTypes.REFRESH, refresh, refreshExpiration);

      request.setAuthorizationToken(access);

      const user = await dispatch(getUser());

      return user;
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const googleLogin = createAsyncThunk(
  `${actionPrefix}/google-login`,
  async (credentials: GoogleRequestPayload, {
    dispatch, rejectWithValue,
  }) => {
    try {
      const {
        data: {
          access,
          refresh,
        },
      } = await api.googleLogin(credentials);

      const { exp: accessExpiration } = jwtDecode<JWTPayload>(access);
      const { exp: refreshExpiration } = jwtDecode<JWTPayload>(refresh);

      setToken(TokenTypes.ACCESS, access, accessExpiration);
      setToken(TokenTypes.REFRESH, refresh, refreshExpiration);

      request.setAuthorizationToken(access);

      const user = await dispatch(getUser());

      return user;
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);
