import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import { mentionAdapter } from './adapters';
import { MentionState } from '../interfaces';

export const {
  selectAll: selectAllMentions,
} =
  mentionAdapter.getSelectors<RootState>(state => state.mention.list);

const featureStateSelector = (state: RootState): MentionState => state.mention;

export const getMentionsList = createSelector(
  featureStateSelector,
  state => state.list.entities
);

export const getTotalMentions = createSelector(
  featureStateSelector,
  state => state.list.count
);

export const getMentionsListStatus = createSelector(
  featureStateSelector,
  state => state.list.status
);

export const getNextPage = createSelector(
  featureStateSelector,
  state => state.list.next
);

export const getPreviousPage = createSelector(
  featureStateSelector,
  state => state.list.prev
);
