import { createAction } from '@reduxjs/toolkit';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('dashboard');

export const setRowsPerPage = createAction<number>(`${actionPrefix}/rows`);

export const setPage = createAction<number>(`${actionPrefix}/page`);

export const setOrdering = createAction<string | undefined>(`${actionPrefix}/ordering`);

export const setSortBy = createAction<string | undefined>(`${actionPrefix}/sort-by`);

export const setSortOrder = createAction<'asc' | 'desc'>(`${actionPrefix}/sort-order`);
