import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  Background,
  Factor,
  Possibility,
  Score,
  TimeRange,
} from '@/core/interfaces/common';

import { Opportunity } from '../interfaces';

export const archivedOpportunityAdapter = createEntityAdapter<Opportunity>();
export const opportunityAdapter = createEntityAdapter<Opportunity>();
export const backgroundAdapter = createEntityAdapter<Background>();
export const factorsAdapter = createEntityAdapter<Factor>();
export const possibilitiesAdapter = createEntityAdapter<Possibility>();
export const scoresAdapter = createEntityAdapter<Score>();
export const timeRangeAdapter = createEntityAdapter<TimeRange>();
