import { createReducer } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ConfigState } from '../interfaces/state';

export const initialState: ConfigState = {
  appInitialized: false,
};

export const configReducer = createReducer(
  initialState,
  builder => builder
    .addCase(HYDRATE, state => {
      state.appInitialized = true;
    })
);

