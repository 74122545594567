import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import getConfig from 'next/config';
import {
  camelCase,
  snakeCase,
} from 'change-case';
import jwtDecode from 'jwt-decode';

import { Nullable } from '@/core/interfaces/common';

import {
  JWTPayload,
  RefreshTokenPayload,
  TokenTypes,
} from '@/features/Auth/interfaces/auth';

import {
  clearToken,
  getToken,
  setToken,
} from '@/utils/jwt';
import { caseConverter } from '@/utils/case-converter';

const { publicRuntimeConfig } = getConfig() || {};
const { BACKEND_URL } = publicRuntimeConfig || process.env;

// TODO adjust endpoints per project
const UNRESTRICTED_ENDPOINTS = ['auth/jwt/create/', 'auth/jwt/refresh/'];

class Request {
  private instance!: AxiosInstance;

  private refreshToken: Nullable<string>;

  constructor() {
    this.initConfig();
    this.refreshToken = null;
  }

  setAuthorizationToken(token: string) {
    this.instance.defaults.headers.common.Authorization = `JWT ${token}`;
  }

  setRefreshToken(refreshToken: Nullable<string>) {
    this.refreshToken = refreshToken;
  }

  removeAuthorizationToken() {
    delete this.instance.defaults.headers.common.Authorization;
  }

  initConfig() {
    this.instance = axios.create({
      baseURL: (BACKEND_URL && (BACKEND_URL as string).endsWith('/')) ? `${BACKEND_URL}api/` : `${BACKEND_URL}/api/`,
    });

    this.instance.interceptors.request.use(async config => ({
      ...config,
      data: config.data ? caseConverter(config.data, snakeCase) : config.data,
      params: config.params ? caseConverter(config.params, snakeCase) : config.params,
    }));

    this.instance.interceptors.response.use(response => ({
      ...response,
      data: caseConverter(response.data, camelCase),
    }), async error => {
      if (!error.response) {
        return Promise.reject(new Error('Error do not have a response.'));
      }

      // Return any error which is not due to authentication back to the calling service
      if (error.response.status !== 401 || UNRESTRICTED_ENDPOINTS.includes(error.config.url)) {
        return Promise.reject(this.parseError(error));
      }

      // Logout user if token refresh didn't work
      if (error.config.url.includes('auth/jwt/refresh/')) {
        clearToken(TokenTypes.ACCESS);
        clearToken(TokenTypes.REFRESH);

        this.removeAuthorizationToken();

        return Promise.reject(this.parseError(error));
      }

      // Try request again with new token
      await this.getNewToken();

      const { config } = error;

      delete config.headers.Authorization;

      return this.instance(config)
        .then(res => Promise.resolve({
          ...res,
          data: caseConverter(res.data, camelCase),
        }))
        .catch(err => Promise.reject(this.parseError(err)));
    });
  }

  async getNewToken() {
    const refreshToken = getToken(TokenTypes.REFRESH) || this.refreshToken;

    if (!refreshToken) {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }

      throw new Error('Refresh token could not be found');
    }

    clearToken(TokenTypes.ACCESS);

    this.removeAuthorizationToken();

    const {
      data: {
        access,
      },
    } = await this.instance.post<RefreshTokenPayload>('/auth/jwt/refresh/', { refresh: refreshToken });

    const { exp: accessExpiration } = jwtDecode<JWTPayload>(access);

    setToken(TokenTypes.ACCESS, access, accessExpiration);

    this.setAuthorizationToken(access);

    return { access };
  }

  parseError(error: AxiosError): AxiosError {
    const { response } = error;

    const convertedData = caseConverter(response?.data || {}, camelCase);
    let data: unknown;

    if (typeof convertedData === 'string' || typeof convertedData === 'number' || typeof convertedData === 'boolean' || typeof convertedData === 'undefined') {
      data = convertedData;
    } else {
      const nonFieldErorrs: Array<string> = response?.data?.non_field_errors || [];

      data = {
        ...convertedData,
        _error: nonFieldErorrs,
      };
    }

    return {
      ...error,
      response: {
        ...response,
        data,
      } as AxiosResponse,
    };
  }

  get<P, QP = unknown>(
    url: string,
    config?: AxiosRequestConfig & { params?: QP }
  ): AxiosPromise<P> {
    return this.instance.get(url, config);
  }

  post<P>(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.post(url, data, config);
  }

  options<P>(url: string, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.options(url, config);
  }

  patch<P>(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.patch(url, data, config);
  }

  put<P>(url: string, data?: unknown, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.put(url, data, config);
  }

  delete<P>(url: string, config?: AxiosRequestConfig): AxiosPromise<P> {
    return this.instance.delete(url, config);
  }
}

export const request = new Request();
