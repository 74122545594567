import {
  ActionReducerMapBuilder,
  createReducer,
} from '@reduxjs/toolkit';

import {
  setOrdering,
  setPage,
  setRowsPerPage,
  setSortBy,
  setSortOrder,
} from './actions';
import { DashboardState } from '../interfaces';

const initialState: DashboardState = {
  ordering: '-fire_score',
  page: 0,
  rowsPerPage: 10,
  sortBy: 'project firescore',
  sortOrder: 'desc',
};

export const dashboardReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<DashboardState>) => {
    builder
      .addCase(setRowsPerPage, (state, { payload }) => {
        state.rowsPerPage = payload;
      })
      .addCase(setPage, (state, { payload }) => {
        state.page = payload;
      })
      .addCase(setOrdering, (state, { payload }) => {
        state.ordering = payload;
      })
      .addCase(setSortBy, (state, { payload }) => {
        state.sortBy = payload;
      })
      .addCase(setSortOrder, (state, { payload }) => {
        state.sortOrder = payload;
      });
  }
);
