import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';

import { Comment } from '@/core/interfaces/logs';
import {
  Background, Factor, Possibility, Score, TimeRange,
} from '@/core/interfaces/common';

import { GlobalMessageType } from '@/features/GlobalMessage/interfaces';
import { addGlobalMessage } from '@/features/GlobalMessage/store';

import { getActionPrefix } from '@/utils/helpers';

import {
  PatchRiskPayload,
  PutRiskContextPayload,
} from '../interfaces/risk';
import { api } from '../api';
import {
  CommentPayload, PostCommentPayload, PostCommentReplyPayload, PostRiskPayload, Risk,
} from '../interfaces';

const actionPrefix = getActionPrefix('risk');

export const getRisks = createAsyncThunk<
  Array<Risk>,
  number
>(`${actionPrefix}/get-list`, async (id: number, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRisks(id);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risks list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getArchivedRisks = createAsyncThunk<
  Array<Risk>,
  number
>(`${actionPrefix}/get-archived-list`, async (id: number, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRisksArchived(id);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risks list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearRisksList = createAction(`${actionPrefix}/clear-list`);

export const getComments = createAsyncThunk<
  Array<Comment>,
  CommentPayload
>(`${actionPrefix}/get-comments`, async (payload: CommentPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getCommentsList(payload);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get comments list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearCommentsList = createAction<number>(`${actionPrefix}/clear-comments`);
export const clearAllComments = createAction(`${actionPrefix}/clear-all-comments`);

export const postComment = createAsyncThunk<
  Pick<Comment, 'body' | 'createdBy'>,
  PostCommentPayload
>(`${actionPrefix}/post-comment`, async (payload: PostCommentPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.postRiskComment(payload);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: data?.body ?? 'Cannot add comment',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const postCommentReply = createAsyncThunk<
  Pick<Comment, 'body' | 'createdBy'>,
  PostCommentReplyPayload
>(`${actionPrefix}/post-comment-reply`, async (payload: PostCommentReplyPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.postRiskCommentReply(payload);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: data?.body ?? 'Cannot add reply',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getRiskBackgrounds = createAsyncThunk<
  Array<Background>
>(`${actionPrefix}/get-backgrounds`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRiskBackgroundsList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risk sources list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getRiskFactors = createAsyncThunk<
  Array<Factor>
>(`${actionPrefix}/get-factors`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRiskFactorsList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risk factors list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getRiskPossibilities = createAsyncThunk<
  Array<Possibility>
>(`${actionPrefix}/get-possibilities`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRiskPossibilitiesList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risk possibilities list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getRiskScores = createAsyncThunk<
  Array<Score>
>(`${actionPrefix}/get-scores`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRiskScoresList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risk scores list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getRiskTimeRanges = createAsyncThunk<
  Array<TimeRange>
>(`${actionPrefix}/get-time-ranges`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getRiskTimeRangesList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get risk time ranges list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const postRisk = createAsyncThunk<
  Risk,
  PostRiskPayload
>(`${actionPrefix}/post-risk`, async (payload: PostRiskPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.postRisk(payload);

    dispatch(addGlobalMessage({
      message: `${payload.topic} risk added`,
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Problem with adding new risk',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const patchRisk = createAsyncThunk<
  Risk,
  PatchRiskPayload
>(`${actionPrefix}/patch-risk`, async (payload: PatchRiskPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.patchRisk(payload);

    dispatch(addGlobalMessage({
      message: `${payload.data.topic} risk updated`,
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Problem with updating risk',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const putRiskContext = createAsyncThunk<
  Pick<Risk, 'context'>,
  PutRiskContextPayload
>(`${actionPrefix}/put-risk-context`, async (payload: PutRiskContextPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.putRiskContext(payload);

    dispatch(addGlobalMessage({
      message: 'Risk context updated',
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Problem with updating risk context',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});
