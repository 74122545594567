import {
  Background, Factor, Possibility, Score, TimeRange,
} from '@/core/interfaces/common';
import { Comment } from '@/core/interfaces/logs';

import { request } from '@/utils/request';

import {
  CommentPayload,
  Opportunity,
  PatchOpportunityPayload,
  PostCommentPayload,
  PostCommentReplyPayload,
  PostOpportunityPayload,
  PutOpportunityContextPayload,
} from './interfaces/opportunity';

export const api = {
  getArchivedOpportunities: (id: number) => request.get<Array<Opportunity>>(`v1/projects/${id}/opportunity_logs/?is_archived=true`),
  getCommentsList: ({
    project, log,
  }: CommentPayload) => request.get<Array<Comment>>(`v1/projects/${project}/opportunity_logs/${log}/comments/`),
  getOpportunities: (id: number) => request.get<Array<Opportunity>>(`v1/projects/${id}/opportunity_logs/?is_archived=false`),
  getOpportunityBackgroundsList: () => request.get<Array<Background>>('v1/opportunity_backgrounds/'),
  getOpportunityFactorsList: () => request.get<Array<Factor>>('v1/opportunity_factors/'),
  getOpportunityPossibilitiesList: () => request.get<Array<Possibility>>('v1/opportunity_possibilities/'),
  getOpportunityScoresList: () => request.get<Array<Score>>('v1/opportunity_scores/'),
  getOpportunityTimeRangesList: () => request.get<Array<TimeRange>>('v1/opportunity_time_ranges/'),
  patchOpportunity: ({
    id, data,
  }: PatchOpportunityPayload) => request.patch<Opportunity>(`v1/projects/${data.project}/opportunity_logs/${id}/`, data),
  postOpportunity: (data: PostOpportunityPayload) => request.post<Opportunity>(`v1/projects/${data.project}/opportunity_logs/`, data),
  postOpportunityComment: ({
    log,
    project,
    data,
  }: PostCommentPayload) => request.post<Pick<Comment, 'body' | 'createdBy'>>(`v1/projects/${project}/opportunity_logs/${log}/comments/`, data),
  postOpportunityCommentReply: ({
    comment,
    data,
    log,
    project,
  }: PostCommentReplyPayload) => request.post<Pick<Comment, 'body' | 'createdBy'>>(`v1/projects/${project}/opportunity_logs/${log}/comments/${comment}/comments/`, data),
  putOpportunityContext: ({
    projectId,
    opportunityId,
    historyId,
    context,
  }: PutOpportunityContextPayload) => request.put<Pick<Opportunity, 'context'>>(`v1/projects/${projectId}/opportunity_logs/${opportunityId}/history/${historyId}/`, { context }),
};
