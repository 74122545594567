import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  Currency,
  FireScore,
  HistoryFireScore,
  Industry,
  PaginatedResponse,
  PaginationParams,
  ServerChoice,
  SimpleDetails,
  SortPayload,
} from '@/core/interfaces/common';

import { GlobalMessageType } from '@/features/GlobalMessage/interfaces';
import { addGlobalMessage } from '@/features/GlobalMessage/store';

import { getActionPrefix } from '@/utils/helpers';

import {
  AddProjectPayload,
  HistoryContextPaginationParams,
  NpsScoring,
  PatchNpsPayload,
  PatchProjectFirescorePayload,
  PatchProjectPayload,
} from '../interfaces/project';
import { api } from '../api';
import {
  HistoryContext,
  Project,
  ProjectMonitor,
} from '../interfaces';

dayjs.extend(utc);

const actionPrefix = getActionPrefix('project');

export const getProjectNameList = createAsyncThunk<
  Array<SimpleDetails>,
  Partial<SortPayload>
>(`${actionPrefix}/get-name-list`, async (params: Partial<SortPayload> = {}) => {
  const { data } = await api.getProjectNames(params);

  return data;
});

export const clearProjectNameList = createAction(`${actionPrefix}/clear-name-list`);

export const getProjectMonitorList = createAsyncThunk<
  PaginatedResponse<ProjectMonitor>,
  Partial<PaginationParams>
>(`${actionPrefix}/get-monitor-list`, async (params: Partial<PaginationParams>, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getProjectMonitorList(params);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get project monitor list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getHistoryClientFeedback = createAsyncThunk<
  PaginatedResponse<HistoryContext>,
  Partial<HistoryContextPaginationParams>
>(`${actionPrefix}/get-history-client-feedback`, async (params: Partial<PaginationParams>, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getHistoryClientFeedback(params);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get client feedback history',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getHistoryContext = createAsyncThunk<
  PaginatedResponse<HistoryContext>,
  Partial<HistoryContextPaginationParams>
>(`${actionPrefix}/get-history-context`, async (params: Partial<PaginationParams>, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getHistoryContext(params);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get history context',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearMonitorList = createAction(`${actionPrefix}/clear-monitor-list`);

export const getProject = createAsyncThunk<
  Project,
  number
>(`${actionPrefix}/get-project`, async (params, { rejectWithValue }) => {
  try {
    const { data } = await api.getProjectDetails(params);

    return data;
  } catch ({ response: data }) {
    return rejectWithValue(data);
  }
});

export const getProjectFirescore = createAsyncThunk<
  Array<HistoryFireScore>,
  number
>(`${actionPrefix}/get-firescore-history`, async (params, { rejectWithValue }) => {
  try {
    const { data } = await api.getProjectFirescore(params);

    return data;
  } catch ({ response: data }) {
    return rejectWithValue(data);
  }
});

export const clearFirescoreList = createAction(`${actionPrefix}/clear-firescore`);

export const getProjectNpsHistory = createAsyncThunk<
  PaginatedResponse<NpsScoring>,
  Partial<HistoryContextPaginationParams>,
  { rejectValue: NpsScoring }
>(`${actionPrefix}/get-nps-history`, async (params: Partial<PaginationParams>, {
  dispatch, rejectWithValue,
}) => {
  try {
    const { data } = await api.getHistoricalNps(params);

    return data;
  } catch ({ response: data }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get project nps history',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const patchProjectNps = createAsyncThunk<
  Partial<NpsScoring>,
  PatchNpsPayload
>(`${actionPrefix}/patch-project-nps`, async (params: PatchNpsPayload, {
  dispatch, rejectWithValue,
}) => {
  try {
    const { data } = await api.patchProjectNps(params);

    dispatch(addGlobalMessage({
      message: 'Project updated',
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: data }) {
    dispatch(addGlobalMessage({
      message: 'An unexpected error occured',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const patchProject = createAsyncThunk<
  Project,
  PatchProjectPayload
>(`${actionPrefix}/patch-project`, async (params: PatchProjectPayload, { rejectWithValue }) => {
  try {
    const { data } = await api.patchProject(params);

    return data;
  } catch ({ response: data }) {
    return rejectWithValue(data);
  }
});

export const getFirescoreList = createAsyncThunk<
  Array<FireScore>,
  void
>(`${actionPrefix}/get-firescores`, async (_, { rejectWithValue }) => {
  try {
    const { data } = await api.getFirescoreList();

    return data;
  } catch ({ response: data }) {
    return rejectWithValue(data);
  }
});

export const patchProjectFirescore = createAsyncThunk<
  Project,
  PatchProjectFirescorePayload
  >(`${actionPrefix}/patch-project-firescore`,
    async ({
      data: params, ...rest
    }: PatchProjectFirescorePayload, { rejectWithValue }) => {
      try {
        const data = {
          ...params,
          fireScoreUpdatedAt: dayjs().utc()
            .format() as string,
        };

        const { data: responseData } = await api.patchProject({
          data,
          ...rest,
        });

        return responseData;
      } catch ({ response: data }) {
        return rejectWithValue(data);
      }
    });

export const getProjectCountries = createAsyncThunk<
  Array<ServerChoice>,
  void
>(`${actionPrefix}/get-countries`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { actions: { post: { country: { choices } } } },
      } = await api.getProjectOptions();

      return choices;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

/* export const getProjectBios = createAsyncThunk<
  Array<Bio>,
  void
>(`${actionPrefix}/get-bios`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectBios();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  }); */

export const getProjectIndustry = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-industry`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectIndustry();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectOwnership = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-ownership`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectOwnerships();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectMethodology = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-methodologies`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectMethodologies();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectType = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-types`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectTypes();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectClientType = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-client-types`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectClientTypes();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectDesign = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-designs`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectDesigns();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectProcessOwnerships = createAsyncThunk<
  Array<Industry>,
  void
>(`${actionPrefix}/get-process-ownerships`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getProjectProcessOwnerships();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const getProjectCurrencies = createAsyncThunk<
  Array<Currency>,
  void
>(`${actionPrefix}/get-currencies`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getCurrenciesList();

      return data;
    } catch ({ response: data }) {
      return rejectWithValue(data);
    }
  });

export const postProject = createAsyncThunk<
  Project,
  AddProjectPayload
>(`${actionPrefix}/post-project`,
  async (payload, {
    dispatch, rejectWithValue,
  }) => {
    try {
      const { data } = await api.postProject(payload);

      dispatch(addGlobalMessage({
        message: 'Project created',
        type: GlobalMessageType.SUCCESS,
      }));

      return data;
    } catch ({ response: data }) {
      dispatch(addGlobalMessage({
        message: 'Something went wrong. Failed to create a new project.',
        type: GlobalMessageType.ERROR,
      }));

      return rejectWithValue(data);
    }
  });

export const setProjectObserve = createAsyncThunk<
  Project,
  number
>(`${actionPrefix}/set-observe`,
  async (projectId, {
    dispatch,
    rejectWithValue,
  }) => {
    try {
      const { data } = await api.postProjectObserve(projectId);

      dispatch(addGlobalMessage({
        message: 'Success',
        type: GlobalMessageType.SUCCESS,
      }));

      return data;
    } catch ({ response: data }) {
      dispatch(addGlobalMessage({
        message: 'Something went wrong. Failed to observe a project.',
        type: GlobalMessageType.ERROR,
      }));

      return rejectWithValue(data);
    }
  });

export const setScrollIntoLogId = createAction<number>(`${actionPrefix}/set-scroll-into-log-id`);
export const setExpandLogComments = createAction<boolean>(`${actionPrefix}/set-expand-log-comments`);
export const clearScrollIntoLogId = createAction(`${actionPrefix}/clear-scroll-into-log-id`);

export const notifyObservers = createAsyncThunk<
  Project,
  number
>(`${actionPrefix}/set-observe`,
  async (projectId, {
    dispatch,
    rejectWithValue,
  }) => {
    try {
      const { data } = await api.notifyObservers(projectId);

      dispatch(addGlobalMessage({
        message: 'Observers were successfully notified.',
        type: GlobalMessageType.SUCCESS,
      }));

      return data;
    } catch ({ response: data }) {
      dispatch(addGlobalMessage({
        message: 'Something went wrong. Failed to notify observers.',
        type: GlobalMessageType.ERROR,
      }));

      return rejectWithValue(data);
    }
  });
