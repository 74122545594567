import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  Background,
  Factor,
  Possibility,
  Score,
  TimeRange,
} from '@/core/interfaces/common';

import { Risk } from '../interfaces';

export const riskAdapter = createEntityAdapter<Risk>();
export const riskArchivedAdapter = createEntityAdapter<Risk>();
export const backgroundAdapter = createEntityAdapter<Background>();
export const factorsAdapter = createEntityAdapter<Factor>();
export const possibilitiesAdapter = createEntityAdapter<Possibility>();
export const scoresAdapter = createEntityAdapter<Score>();
export const timeRangeAdapter = createEntityAdapter<TimeRange>();
