import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';

import {
  PaginatedResponse, PaginationParams,
} from '@/core/interfaces/common';

import { GlobalMessageType } from '@/features/GlobalMessage/interfaces';
import { addGlobalMessage } from '@/features/GlobalMessage/store';

import { getActionPrefix } from '@/utils/helpers';

import { api } from '../api';
import {
  NotificationParams, SimpleNotification,
} from '../interfaces';

const actionPrefix = getActionPrefix('notification');

export const getNotifications = createAsyncThunk<
  PaginatedResponse<SimpleNotification> & { countUnread: number },
  Partial<NotificationParams>
>(`${actionPrefix}/get-list`, async (params: Partial<PaginationParams> = {}, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getNotifications(params);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Unable to get notifications',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearNotificationsList = createAction<{ keepUnreadCount?: boolean } | undefined>(`${actionPrefix}/clear-list`);

export const setNotificationAsRead = createAsyncThunk<
  SimpleNotification,
  number
>(`${actionPrefix}/update-notification-status`, async (id: number) => {
  const { data } = await api.setAsRead(id);

  return data;
});

export const deleteNotification = createAsyncThunk<
  number,
  number
>(`${actionPrefix}/delete-notification`, async (id: number, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    await api.deleteNotification(id);

    dispatch(addGlobalMessage({
      message: 'Notification deleted',
      type: GlobalMessageType.SUCCESS,
    }));

    return id;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Unable to delete notification',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});
