import {
  ActionReducerMapBuilder,
  createReducer,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import {
  clearEmployees, getEmployees, getRoles,
} from './actions';
import {
  employeeAdapter, roleAdapter,
} from './adapters';
import { EmployeeState } from '../interfaces';

const listInitialState = employeeAdapter.getInitialState({
  status: RequestStatus.IDLE,
});

const rolesInitialState = roleAdapter.getInitialState();

const initialState: EmployeeState = {
  list: listInitialState,
  roles: rolesInitialState,
};

export const employeeReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<EmployeeState>) => {
    builder
      .addCase(getEmployees.pending, ({ list }) => {
        list.status = RequestStatus.LOADING;
      })
      .addCase(getEmployees.fulfilled, ({ list }, { payload }) => {
        employeeAdapter.setAll(list, payload);

        list.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getEmployees.rejected, ({ list }) => {
        list.status = RequestStatus.FAILED;
      })
      .addCase(clearEmployees, ({ list }) => {
        employeeAdapter.removeAll(list);

        list.status = RequestStatus.IDLE;
      })
      .addCase(getRoles.fulfilled, ({ roles }, { payload }) => {
        roleAdapter.setAll(roles, payload);
      });
  }
);
