import {
  ActionReducerMapBuilder,
  createReducer,
  isAnyOf,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import {
  clearNotificationsList,
  deleteNotification,
  getNotifications,
  setNotificationAsRead,
} from './actions';
import { notificationAdapter } from './adapters';
import { NotificationState } from '../interfaces';

const listInitialState = notificationAdapter.getInitialState({
  count: 0,
  countUnread: 0,
  next: null,
  prev: null,
  status: RequestStatus.IDLE,
});

const initialState: NotificationState = {
  list: listInitialState,
};

export const notificationReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<NotificationState>) => {
    builder
      .addCase(getNotifications.fulfilled, ({ list }, { payload }) => {
        notificationAdapter.upsertMany(list, payload.results);
        list.count = payload.count;
        list.countUnread = payload.countUnread;
        list.next = payload.next;
        list.prev = payload.prev;
        list.status = RequestStatus.SUCCEEDED;
      })
      .addCase(clearNotificationsList, ({ list }, { payload }) => {
        notificationAdapter.removeAll(list);
        list.count = 0;
        list.countUnread = payload?.keepUnreadCount ? list.countUnread : 0;
        list.next = null;
        list.prev = null;
        list.status = RequestStatus.IDLE;
      })
      .addCase(setNotificationAsRead.fulfilled, ({ list }, { payload }) => {
        notificationAdapter.upsertOne(list, payload);
        list.countUnread -= 1;
        list.status = RequestStatus.SUCCEEDED;
      })
      .addCase(deleteNotification.fulfilled, ({ list }, { payload }) => {
        notificationAdapter.removeOne(list, payload);
        list.status = RequestStatus.SUCCEEDED;
      })
      .addMatcher(isAnyOf(
        getNotifications.pending,
        setNotificationAsRead.pending,
        deleteNotification.pending
      ), ({ list }) => {
        list.status = RequestStatus.LOADING;
      })
      .addMatcher(isAnyOf(
        getNotifications.rejected,
        setNotificationAsRead.rejected,
        deleteNotification.rejected
      ), ({ list }) => {
        list.status = RequestStatus.FAILED;
      });
  }
);
