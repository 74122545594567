import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';

import { Comment } from '@/core/interfaces/logs';
import {
  Background, Factor, Possibility, Score, TimeRange,
} from '@/core/interfaces/common';

import { GlobalMessageType } from '@/features/GlobalMessage/interfaces';
import { addGlobalMessage } from '@/features/GlobalMessage/store';

import { getActionPrefix } from '@/utils/helpers';

import {
  PatchOpportunityPayload,
  PutOpportunityContextPayload,
} from '../interfaces/opportunity';
import { api } from '../api';
import {
  CommentPayload, Opportunity, PostCommentPayload, PostCommentReplyPayload, PostOpportunityPayload,
} from '../interfaces';

const actionPrefix = getActionPrefix('opportunity');

export const getArchivedOpportunities = createAsyncThunk<
  Array<Opportunity>,
  number
>(`${actionPrefix}/get-archived-list`, async (id: number, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getArchivedOpportunities(id);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunities list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getOpportunities = createAsyncThunk<
  Array<Opportunity>,
  number
>(`${actionPrefix}/get-list`, async (id: number, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getOpportunities(id);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunities list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearOpportunitiesList = createAction(`${actionPrefix}/clear-list`);

export const getComments = createAsyncThunk<
  Array<Comment>,
  CommentPayload
>(`${actionPrefix}/get-comments`, async (payload: CommentPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getCommentsList(payload);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get comments list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearCommentsList = createAction<number>(`${actionPrefix}/clear-comments`);
export const clearAllComments = createAction(`${actionPrefix}/clear-all-comments`);

export const postComment = createAsyncThunk<
  Pick<Comment, 'body' | 'createdBy'>,
  PostCommentPayload
>(`${actionPrefix}/post-comment`, async (payload: PostCommentPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.postOpportunityComment(payload);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: data?.body ?? 'Cannot add comment',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const postCommentReply = createAsyncThunk<
  Pick<Comment, 'body' | 'createdBy'>,
  PostCommentReplyPayload
>(`${actionPrefix}/post-comment-reply`, async (payload: PostCommentReplyPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.postOpportunityCommentReply(payload);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: data?.body ?? 'Cannot add reply',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getOpportunityBackgrounds = createAsyncThunk<
  Array<Background>
>(`${actionPrefix}/get-backgrounds`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getOpportunityBackgroundsList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunity sources list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getOpportunityFactors = createAsyncThunk<
  Array<Factor>
>(`${actionPrefix}/get-factors`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getOpportunityFactorsList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunity factors list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getOpportunityPossibilities = createAsyncThunk<
  Array<Possibility>
>(`${actionPrefix}/get-possibilities`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getOpportunityPossibilitiesList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunity possibilities list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getOpportunityScores = createAsyncThunk<
  Array<Score>
>(`${actionPrefix}/get-scores`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getOpportunityScoresList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunity scores list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const getOpportunityTimeRanges = createAsyncThunk<
  Array<TimeRange>
>(`${actionPrefix}/get-time-ranges`, async (_, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.getOpportunityTimeRangesList();

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get opportunity time ranges list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const postOpportunity = createAsyncThunk<
  Opportunity,
  PostOpportunityPayload
>(`${actionPrefix}/post-opportunity`, async (payload: PostOpportunityPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.postOpportunity(payload);

    dispatch(addGlobalMessage({
      message: `${payload.topic} opportunity added`,
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Problem with adding new opportunity',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const patchOpportunity = createAsyncThunk<
  Opportunity,
  PatchOpportunityPayload
>(`${actionPrefix}/patch-opportunity`, async (payload: PatchOpportunityPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.patchOpportunity(payload);

    dispatch(addGlobalMessage({
      message: `${payload.data.topic} opportunity updated`,
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Problem with updating opportunity',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const putOpportunityContext = createAsyncThunk<
  Pick<Opportunity, 'context'>,
  PutOpportunityContextPayload
>(`${actionPrefix}/put-opportunity-context`, async (payload: PutOpportunityContextPayload, {
  dispatch,
  rejectWithValue,
}) => {
  try {
    const { data } = await api.putOpportunityContext(payload);

    dispatch(addGlobalMessage({
      message: 'Opportunity context updated',
      type: GlobalMessageType.SUCCESS,
    }));

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Problem with updating opportunity context',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});
