import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';

import {
  Nullable,
  SortPayload,
} from '@/core/interfaces/common';

import { SimpleProject } from '@/features/Project/interfaces';
import { addGlobalMessage } from '@/features/GlobalMessage/store';
import { GlobalMessageType } from '@/features/GlobalMessage/interfaces';

import { getActionPrefix } from '@/utils/helpers';

import { SimpleProjectManager } from '../interfaces/projectManager';
import { api } from '../api';

const actionPrefix = getActionPrefix('projectManager');

export const getProjectManagersList = createAsyncThunk<
  Array<SimpleProjectManager>,
  Partial<SortPayload>
>(`${actionPrefix}/get-list`, async (params: Partial<SortPayload> = {}, {
  dispatch, rejectWithValue,
}) => {
  try {
    const { data } = await api.getProjectManagers(params);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get project managers list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearProjectManagersList = createAction(`${actionPrefix}/clear-list`);

export const setActiveProjectManager = createAction<Nullable<string>>(`${actionPrefix}/set-active`);

export const getProjectsList = createAsyncThunk<
  Array<SimpleProject>,
  string
>(`${actionPrefix}/get-projects-list`, async (id, {
  dispatch, rejectWithValue,
}) => {
  try {
    const { data } = await api.getProjects(id);

    return data;
  } catch ({ response: { data } }) {
    dispatch(addGlobalMessage({
      message: 'Cannot get projects list',
      type: GlobalMessageType.ERROR,
    }));

    return rejectWithValue(data);
  }
});

export const clearProjectManagersProjectsList = createAction(`${actionPrefix}/clear-projects-list`);
