import { SortPayload } from '@/core/interfaces/common';

import { request } from '@/utils/request';

import { SimpleProject } from '../Project/interfaces';
import { SimpleProjectManager } from './interfaces/projectManager';

export const api = {
  getProjectManagers: (params: Partial<SortPayload>) => request.get<Array<SimpleProjectManager>>('v1/project_managers/', { params }),
  getProjects: (id: string) => request.get<Array<SimpleProject>>(`v1/project_managers/${id}/projects/`),
};
