import {
  ActionReducerMapBuilder,
  createReducer,
} from '@reduxjs/toolkit';

import {
  RequestStatus,
} from '@/core/interfaces/common';

import {
  IntegrationState,
} from '@/features/Integration/interfaces';
import {
  clearSlackChannels,
  getSlackChannels,
} from '@/features/Integration/store/actions';
import {
  slackAdapter,
} from '@/features/Integration/store/adapters';

const slackInitialState = slackAdapter.getInitialState({
  status: RequestStatus.IDLE,
});

const initialState: IntegrationState = {
  slackChannels: slackInitialState,
};

export const integrationReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<IntegrationState>) => {
    builder
      .addCase(getSlackChannels.pending, ({ slackChannels }) => {
        slackChannels.status = RequestStatus.LOADING;
      })
      .addCase(getSlackChannels.fulfilled, ({ slackChannels }, { payload }) => {
        slackAdapter.setAll(slackChannels, payload);

        slackChannels.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getSlackChannels.rejected, ({ slackChannels }) => {
        slackAdapter.removeAll(slackChannels);

        slackChannels.status = RequestStatus.FAILED;
      })
      .addCase(clearSlackChannels, ({ slackChannels }) => {
        slackAdapter.removeAll(slackChannels);
      });
  }
);
