import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { Action } from 'redux';
import {
  ThunkAction,
  ThunkDispatch,
} from 'redux-thunk';
import { useDispatch } from 'react-redux';

import { rootReducer } from '@/core/store/reducers/root';
import { RootState } from '@/core/interfaces/store';

const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: rootReducer,
});

if (module.hot) {
  module.hot.accept('./reducers/root.ts', () => {
    const nextReducer = rootReducer;

    store.replaceReducer(nextReducer);
  });
}

const makeStore = () => store;

export const wrapper = createWrapper(makeStore);
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppDispatch = typeof store.dispatch

export type AppThunk<R = void> = ThunkAction<R, RootState, null, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, null, Action<string>>;
