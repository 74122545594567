import {
  ActionReducerMapBuilder,
  createReducer,
  isAnyOf,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import {
  clearAllComments,
  clearCommentsList,
  clearOpportunitiesList,
  getArchivedOpportunities,
  getComments, getOpportunities,
  getOpportunityBackgrounds,
  getOpportunityFactors, getOpportunityPossibilities, getOpportunityScores,
  getOpportunityTimeRanges, patchOpportunity, postComment, postCommentReply, postOpportunity,
  putOpportunityContext,
} from './actions';
import {
  archivedOpportunityAdapter,
  backgroundAdapter,
  factorsAdapter,
  opportunityAdapter,
  possibilitiesAdapter,
  scoresAdapter,
  timeRangeAdapter,
} from './adapters';
import {
  OpportunityCommentsState,
  OpportunityState,
} from '../interfaces';

const listInitialState = opportunityAdapter.getInitialState({
  isSaving: RequestStatus.IDLE,
  status: RequestStatus.IDLE,
});
const backgroundInitialState = backgroundAdapter.getInitialState();
const factorInitialState = factorsAdapter.getInitialState();
const possibilityState = possibilitiesAdapter.getInitialState();
const scoreState = scoresAdapter.getInitialState();
const timeRangeState = timeRangeAdapter.getInitialState();

const commentsInitialState: OpportunityCommentsState = {
  data: [],
  status: RequestStatus.IDLE,
};

const initialState: OpportunityState = {
  archivedList: listInitialState,
  backgrounds: backgroundInitialState,
  comments: {},
  factors: factorInitialState,
  list: listInitialState,
  possibilities: possibilityState,
  scores: scoreState,
  timeRanges: timeRangeState,
};

export const opportunityReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<OpportunityState>) => {
    builder
      .addCase(getArchivedOpportunities.pending, ({ archivedList }) => {
        archivedList.status = RequestStatus.LOADING;
      })
      .addCase(getArchivedOpportunities.fulfilled, ({ archivedList }, { payload }) => {
        opportunityAdapter.setAll(archivedList, payload);
        archivedList.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getArchivedOpportunities.rejected, ({ archivedList }) => {
        archivedList.status = RequestStatus.FAILED;
      })
      .addCase(getOpportunities.pending, ({ list }) => {
        list.status = RequestStatus.LOADING;
      })
      .addCase(getOpportunities.fulfilled, ({ list }, { payload }) => {
        opportunityAdapter.setAll(list, payload);
        list.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getOpportunities.rejected, ({ list }) => {
        list.status = RequestStatus.FAILED;
      })
      .addCase(clearOpportunitiesList, ({
        list,
      }) => {
        opportunityAdapter.removeAll(list);
        list.status = RequestStatus.IDLE;
        list.isSaving = RequestStatus.IDLE;
      })
      .addCase(getComments.fulfilled, ({ comments }, {
        meta: { arg }, payload,
      }) => {
        comments[arg.log] = {
          data: payload,
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(getComments.rejected, ({ comments }, { meta: { arg } }) => {
        if (!comments[arg.log]) comments[arg.log] = commentsInitialState;

        comments[arg.log] = {
          ...comments[arg.log],
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(clearCommentsList, ({ comments }, { payload }) => {
        comments[payload] = commentsInitialState;
      })
      .addCase(clearAllComments, state => {
        state.comments = {};
      })
      .addCase(postComment.fulfilled, ({ comments }, {
        meta: { arg },
      }) => {
        comments[arg.log] = {
          ...comments[arg.log],
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(postCommentReply.fulfilled, ({ comments }, {
        meta: { arg },
      }) => {
        comments[arg.log] = {
          ...comments[arg.log],
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(getOpportunityScores.fulfilled,
        ({ scores }, { payload }) => {
          scoresAdapter.addMany(scores, payload);
        })
      .addCase(getOpportunityBackgrounds.fulfilled,
        ({ backgrounds }, { payload }) => {
          backgroundAdapter.addMany(backgrounds, payload);
        })
      .addCase(getOpportunityFactors.fulfilled,
        ({ factors }, { payload }) => {
          factorsAdapter.addMany(factors, payload);
        })
      .addCase(getOpportunityTimeRanges.fulfilled,
        ({ timeRanges }, { payload }) => {
          timeRangeAdapter.addMany(timeRanges, payload);
        })
      .addCase(getOpportunityPossibilities.fulfilled,
        ({ possibilities }, { payload }) => {
          possibilitiesAdapter.addMany(possibilities, payload);
        })
      .addMatcher(
        isAnyOf(
          postOpportunity.pending,
          patchOpportunity.pending,
          putOpportunityContext.pending
        ),
        ({ list }) => {
          list.isSaving = RequestStatus.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(
          postOpportunity.fulfilled,
          patchOpportunity.fulfilled,
          putOpportunityContext.fulfilled
        ),
        ({ list }) => {
          list.isSaving = RequestStatus.SUCCEEDED;
        }
      )
      .addMatcher(
        isAnyOf(
          postOpportunity.rejected,
          patchOpportunity.rejected,
          putOpportunityContext.rejected
        ),
        ({ list }) => {
          list.isSaving = RequestStatus.FAILED;
        }
      )
      .addMatcher(
        isAnyOf(getComments.pending, postComment.pending, postCommentReply.pending),
        ({ comments }, { meta: { arg } }) => {
          if (!comments[arg.log]) comments[arg.log] = commentsInitialState;

          comments[arg.log] = {
            ...comments[arg.log],
            status: RequestStatus.LOADING,
          };
        }
      );
  }
);
