import {
  // Bio,
  Currency,
  FireScore,
  HistoryFireScore,
  Industry,
  PaginatedResponse,
  PaginationParams,
  ProcessOwnership,
  SimpleDetails,
  SortPayload,
} from '@/core/interfaces/common';

import { request } from '@/utils/request';

import {
  AddProjectPayload,
  HistoryContextPaginationParams,
  NpsScoring,
  PatchNpsPayload,
  PatchProjectPayload,
  Project,
  ProjectOptions,
} from './interfaces/project';
import {
  HistoryContext,
  ProjectMonitor,
} from './interfaces';

export const api = {
  getCurrenciesList: () => request.get<Array<Currency>>('v1/currencies/'),
  getFirescoreList: () => request.get<Array<FireScore>>('v1/project_fire_scores/'),
  getHistoricalNps: (params: Partial<HistoryContextPaginationParams>) => request.get<PaginatedResponse<NpsScoring>>(`v1/nps_changed_history/${params.id}/`, { params }),
  getHistoryClientFeedback: (params: Partial<HistoryContextPaginationParams>) => request
    .get<PaginatedResponse<HistoryContext>>(`v1/projects/${params.id}/client_feedback_history/`, { params }),
  getHistoryContext: (params: Partial<HistoryContextPaginationParams>) => request
    .get<PaginatedResponse<HistoryContext>>(`v1/projects/${params.id}/context_history/`, { params }),
  // getProjectBios: () => request.get<Array<Bio>>('v1/project_bios/'),
  getProjectClientTypes: () => request.get<Array<Industry>>('v1/project_client_types/'),
  getProjectDesigns: () => request.get<Array<Industry>>('v1/project_designs/'),
  getProjectDetails: (id: number) => request.get<Project>(`v1/projects/${id}/aggregated/`),
  getProjectFirescore: (id: number) => request.get<Array<HistoryFireScore>>(`v1/projects/${id}/firescore_history/`),
  getProjectIndustry: () => request.get<Array<Industry>>('v1/project_industry/'),
  getProjectMethodologies: () => request.get<Array<Industry>>('v1/project_methodologies/'),
  getProjectMonitorList: (params: Partial<PaginationParams>) => request.get<PaginatedResponse<ProjectMonitor>>('v1/project_monitor/', { params }),
  getProjectNames: (params: Partial<SortPayload>) => request.get<Array<SimpleDetails>>('v1/project_names/', { params }),
  getProjectOptions: () => request.options<ProjectOptions>('v1/projects/'),
  getProjectOwnerships: () => request.get<Array<Industry>>('v1/project_ownerships/'),
  getProjectProcessOwnerships: () => request.get<Array<ProcessOwnership>>('v1/project_process_ownerships/'),
  getProjectTypes: () => request.get<Array<Industry>>('v1/project_types/'),
  notifyObservers: (id: number) => request.post<Project>(`v1/projects/${id}/notify_observers/`),
  patchProject: ({
    id, data,
  }: PatchProjectPayload) => request.patch<Project>(`v1/projects/${id}/`, data),
  patchProjectNps: ({
    id, payload,
  }: PatchNpsPayload) => request.patch<NpsScoring>(`v1/project_nps_score/${id}/`, payload),
  postProject: (data: AddProjectPayload) => request.post<Project>('v1/projects/', data),
  postProjectObserve: (id: number) => request.post<Project>(`v1/projects/${id}/observe_unobserve/`),
};
