import {
  ActionReducerMapBuilder,
  createReducer,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import {
  clearMentionsList, getMentions,
} from './actions';
import { mentionAdapter } from './adapters';
import { MentionState } from '../interfaces';

const listInitialState = mentionAdapter.getInitialState({
  count: 0,
  next: null,
  prev: null,
  status: RequestStatus.IDLE,
});

const initialState: MentionState = {
  list: listInitialState,
};

export const mentionReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<MentionState>) => {
    builder
      .addCase(getMentions.pending, ({ list }) => {
        list.status = RequestStatus.LOADING;
      })
      .addCase(getMentions.fulfilled, ({ list }, action) => {
        const {
          count,
          prev,
          next,
          results,
        } = action.payload;

        mentionAdapter.setAll(list, results);

        list.status = RequestStatus.SUCCEEDED;
        list.count = count;
        list.prev = prev;
        list.next = next;
      })
      .addCase(getMentions.rejected, ({ list }) => {
        list.status = RequestStatus.FAILED;
      })
      .addCase(clearMentionsList, ({ list }) => {
        mentionAdapter.removeAll(list);
        list.count = 0;
        list.next = null;
        list.prev = null;
      });
  }
);
