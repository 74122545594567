import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import { IntegrationState } from '@/features/Integration/interfaces';
import {
  slackAdapter,
} from '@/features/Integration/store/adapters';

export const {
  selectAll: selectSlackChannels,
  selectEntities: selectSlackChannelsEntities,
} = slackAdapter.getSelectors<RootState>(state => state.integration.slackChannels);

const featureStateSelector = (state: RootState): IntegrationState => state.integration;

export const getSlackChannelsStatus = createSelector(
  featureStateSelector,
  state => state.slackChannels.status
);
