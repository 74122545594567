import {
  ActionReducerMapBuilder,
  createReducer,
  isAnyOf,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import {
  clearAllComments,
  clearCommentsList,
  clearRisksList, getArchivedRisks, getComments, getRiskBackgrounds,
  getRiskFactors, getRiskPossibilities, getRisks, getRiskScores,
  getRiskTimeRanges, patchRisk, postComment, postCommentReply, postRisk,
  putRiskContext,
} from './actions';
import {
  backgroundAdapter,
  factorsAdapter,
  possibilitiesAdapter,
  riskAdapter,
  riskArchivedAdapter,
  scoresAdapter,
  timeRangeAdapter,
} from './adapters';
import {
  RiskCommentsState,
  RiskState,
} from '../interfaces';

const listInitialState = riskAdapter.getInitialState({
  isSaving: RequestStatus.IDLE,
  status: RequestStatus.IDLE,
});
const backgroundInitialState = backgroundAdapter.getInitialState();
const factorInitialState = factorsAdapter.getInitialState();
const possibilityState = possibilitiesAdapter.getInitialState();
const scoreState = scoresAdapter.getInitialState();
const timeRangeState = timeRangeAdapter.getInitialState();

const commentsInitialState: RiskCommentsState = {
  data: [],
  status: RequestStatus.IDLE,
};

const initialState: RiskState = {
  backgrounds: backgroundInitialState,
  comments: {},
  factors: factorInitialState,
  list: listInitialState,
  listArchived: listInitialState,
  possibilities: possibilityState,
  scores: scoreState,
  timeRanges: timeRangeState,
};

export const riskReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<RiskState>) => {
    builder
      .addCase(getArchivedRisks.pending, ({ listArchived }) => {
        listArchived.status = RequestStatus.LOADING;
      })
      .addCase(getArchivedRisks.fulfilled, ({ listArchived }, { payload }) => {
        riskArchivedAdapter.setAll(listArchived, payload);
        listArchived.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getArchivedRisks.rejected, ({ listArchived }) => {
        listArchived.status = RequestStatus.FAILED;
      })
      .addCase(getRisks.pending, ({ list }) => {
        list.status = RequestStatus.LOADING;
      })
      .addCase(getRisks.fulfilled, ({ list }, { payload }) => {
        riskAdapter.setAll(list, payload);
        list.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getRisks.rejected, ({ list }) => {
        list.status = RequestStatus.FAILED;
      })
      .addCase(clearRisksList, ({
        list,
      }) => {
        riskAdapter.removeAll(list);
        list.status = RequestStatus.IDLE;
        list.isSaving = RequestStatus.IDLE;
      })
      .addCase(getComments.fulfilled, ({ comments }, {
        meta: { arg }, payload,
      }) => {
        comments[arg.log] = {
          data: payload,
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(getComments.rejected, ({ comments }, { meta: { arg } }) => {
        if (!comments[arg.log]) comments[arg.log] = commentsInitialState;

        comments[arg.log] = {
          ...comments[arg.log],
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(clearCommentsList, ({ comments }, { payload }) => {
        comments[payload] = commentsInitialState;
      })
      .addCase(clearAllComments, state => {
        state.comments = {};
      })
      .addCase(postComment.fulfilled, ({ comments }, {
        meta: { arg },
      }) => {
        comments[arg.log] = {
          ...comments[arg.log],
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(postCommentReply.fulfilled, ({ comments }, {
        meta: { arg },
      }) => {
        comments[arg.log] = {
          ...comments[arg.log],
          status: RequestStatus.SUCCEEDED,
        };
      })
      .addCase(getRiskScores.fulfilled,
        ({ scores }, { payload }) => {
          scoresAdapter.addMany(scores, payload);
        })
      .addCase(getRiskBackgrounds.fulfilled,
        ({ backgrounds }, { payload }) => {
          backgroundAdapter.addMany(backgrounds, payload);
        })
      .addCase(getRiskFactors.fulfilled,
        ({ factors }, { payload }) => {
          factorsAdapter.addMany(factors, payload);
        })
      .addCase(getRiskTimeRanges.fulfilled,
        ({ timeRanges }, { payload }) => {
          timeRangeAdapter.addMany(timeRanges, payload);
        })
      .addCase(getRiskPossibilities.fulfilled,
        ({ possibilities }, { payload }) => {
          possibilitiesAdapter.addMany(possibilities, payload);
        })
      .addMatcher(
        isAnyOf(postRisk.pending, patchRisk.pending, putRiskContext.pending),
        ({ list }) => {
          list.isSaving = RequestStatus.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(postRisk.fulfilled, patchRisk.fulfilled, putRiskContext.fulfilled),
        ({ list }) => {
          list.isSaving = RequestStatus.SUCCEEDED;
        }
      )
      .addMatcher(
        isAnyOf(postRisk.rejected, patchRisk.rejected, putRiskContext.rejected),
        ({ list }) => {
          list.isSaving = RequestStatus.FAILED;
        }
      )
      .addMatcher(
        isAnyOf(getComments.pending, postComment.pending, postCommentReply.pending),
        ({ comments }, { meta: { arg } }) => {
          if (!comments[arg.log]) comments[arg.log] = commentsInitialState;

          comments[arg.log] = {
            ...comments[arg.log],
            status: RequestStatus.LOADING,
          };
        }
      );
  }
);
