import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import {
  historyClientFeedbackAdapter,
  historyContextAdapter,
  projectBioAdapter,
  projectClientTypeAdapter,
  projectCountryAdapter,
  projectCurrencyAdapter,
  projectDesignAdapter,
  projectFirescoreAdapter,
  projectFirescoreHistoryAdapter,
  projectIndustryAdapter,
  projectMethodologyAdapter,
  projectMonitorAdapter,
  projectNameAdapter,
  projectOwnershipAdapter,
  projectProcessOwnershipsAdapter,
  projectTypeAdapter,
} from './adapters';
import { ProjectState } from '../interfaces';

const featureStateSelector = (state: RootState): ProjectState => state.project;

export const {
  selectAll: selectAllProjectNames,
} =
  projectNameAdapter.getSelectors<RootState>(state => state.project.names);

export const {
  selectAll: selectAllProjectMonitors,
} =
  projectMonitorAdapter.getSelectors<RootState>(state => state.project.monitors);

export const {
  selectAll: selectAllHistoryContext,
} = historyContextAdapter.getSelectors<RootState>(state => state.project.historyContext);

export const {
  selectAll: selectAllHistoryClientFeedback,
} = historyClientFeedbackAdapter.getSelectors<RootState>(
  state => state.project.historyClientFeedback
);

export const {
  selectAll: selectAllProjectFirescoresHistory,
} = projectFirescoreHistoryAdapter.getSelectors<RootState>(state => state.project.firescoreHistory);

export const {
  selectAll: selectAllProjectFirescores,
} = projectFirescoreAdapter.getSelectors<RootState>(state => state.project.firescores);

export const {
  selectAll: selectAllProjectCountries,
  selectEntities: selectAllCountryEntities,
} = projectCountryAdapter.getSelectors<RootState>(state => state.project.countries);

export const {
  selectAll: selectAllProjectBios,
  selectEntities: selectAllBiosEntities,
} = projectBioAdapter.getSelectors<RootState>(state => state.project.bios);

export const {
  selectAll: selectAllProjectIndustry,
  selectEntities: selectAllIndustryEntities,
} = projectIndustryAdapter.getSelectors<RootState>(state => state.project.industry);

export const {
  selectAll: selectAllProjectOwnerships,
  selectEntities: selectAllOwnershipEntities,
} = projectOwnershipAdapter.getSelectors<RootState>(state => state.project.ownership);

export const {
  selectAll: selectAllProjectMethodologies,
  selectEntities: selectAllMethodologyEntities,
} = projectMethodologyAdapter.getSelectors<RootState>(state => state.project.methodology);

export const {
  selectAll: selectAllProjectTypes,
  selectEntities: selectAllTypeEntities,
} = projectTypeAdapter.getSelectors<RootState>(state => state.project.type);

export const {
  selectAll: selectAllProjectClientTypes,
  selectEntities: selectAllClientTypeEntities,
} = projectClientTypeAdapter.getSelectors<RootState>(state => state.project.clientType);

export const {
  selectAll: selectAllProjectDesigns,
  selectEntities: selectAllDesignEntities,
} = projectDesignAdapter.getSelectors<RootState>(state => state.project.design);

export const {
  selectAll: selectAllProjectProcessOwnerships,
  selectEntities: selectAllProcessOwnershipEntities,
} = projectProcessOwnershipsAdapter.getSelectors<RootState>(
  state => state.project.processOwnerships
);

export const {
  selectAll: selectAllProjectCurrencies,
  selectEntities: selectAllCurrencyEntities,
} = projectCurrencyAdapter.getSelectors<RootState>(
  state => state.project.currencies
);

export const getProjectNamesListStatusSelector = createSelector(
  featureStateSelector,
  state => state.names.status
);

export const getProjectMonitorListStatus = createSelector(
  featureStateSelector,
  state => state.monitors.status
);

export const getTotalMonitors = createSelector(
  featureStateSelector,
  state => state.monitors.count
);

export const getHistoryContextCount = createSelector(
  featureStateSelector,
  state => state.historyContext.count
);
export const getNpsHistory = createSelector(
  featureStateSelector,
  state => state.npsHistory
);

export const getNpsHistoryCount = createSelector(
  featureStateSelector,
  state => state.npsHistory.count
);
export const getNpsHistoryStatus = createSelector(
  featureStateSelector,
  state => state.npsHistory.status
);
export const getHistoryClientFeedbackCount = createSelector(
  featureStateSelector,
  state => state.historyClientFeedback.count
);

export const getHistoryContextStatus = createSelector(
  featureStateSelector,
  state => state.historyContext.status
);

export const getHistoryClientFeedbackStatus = createSelector(
  featureStateSelector,
  state => state.historyClientFeedback.status
);

export const getProjectStatus = createSelector(
  featureStateSelector,
  state => state.project.status
);

export const getProjectSavingStatus = createSelector(
  featureStateSelector,
  state => state.project.isSaving
);

export const getScrollIntoLogId = createSelector(
  featureStateSelector,
  state => state.transitionData.scrollIntoLogId
);

export const getExpandLogComments = createSelector(
  featureStateSelector,
  state => state.transitionData.expandLogComments
);

export const getProjectData = createSelector(
  featureStateSelector,
  state => state.project.data
);

export const getProjectFirescoreStatus = createSelector(
  featureStateSelector,
  state => state.firescoreHistory.status
);
