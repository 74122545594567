import {
  SlackChannel,
  SlackChannelPayload,
} from '@/features/Integration/interfaces';

import { request } from '@/utils/request';

export const api = {
  getSlackChannels: (params: SlackChannelPayload) => request.get<Array<SlackChannel>>('/v1/slack_channels/', { params }),
};
