import {
  AuthUser,
  GoogleRequestPayload,
  LoginRequestData,
  LoginRequestPayload,
} from '@/features/Auth/interfaces';

import { request } from '@/utils/request';

export const api = {
  getUser: () => request.get<AuthUser>('auth/users/me/'),
  googleLogin: (
    credentials: GoogleRequestPayload
  ) => request.post<LoginRequestPayload>('auth/authenticate/', credentials),
  login: (
    credentials: LoginRequestData
  ) => request.post<LoginRequestPayload>('auth/jwt/create/', credentials),
};
