import {
  createAction, createAsyncThunk,
} from '@reduxjs/toolkit';

import {
  PaginatedResponse, PaginationParams,
} from '@/core/interfaces/common';

import { getActionPrefix } from '@/utils/helpers';

import { api } from '../api';
import { SimpleMention } from '../interfaces';

const actionPrefix = getActionPrefix('mention');

export const getMentions = createAsyncThunk<
  PaginatedResponse<SimpleMention>,
  Partial<PaginationParams>
>(`${actionPrefix}/get-list`, async (params: Partial<PaginationParams>) => {
  const { data } = await api.getMentions(params);

  return data;
});

export const clearMentionsList = createAction(`${actionPrefix}/clear-list`);
