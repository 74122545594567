import {
  ActionReducerMapBuilder,
  createReducer,
  isAnyOf,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import { projectManagersProjectAdapter } from '@/features/ProjectManager/store/adapters';

import {
  historyClientFeedbackAdapter,
  historyContextAdapter,
  projectBioAdapter,
  projectClientTypeAdapter,
  projectCountryAdapter,
  projectCurrencyAdapter,
  projectDesignAdapter,
  projectFirescoreAdapter,
  projectFirescoreHistoryAdapter,
  projectIndustryAdapter,
  projectMethodologyAdapter,
  projectMonitorAdapter,
  projectNameAdapter,
  projectNpsHistoryAdapter,
  projectOwnershipAdapter,
  projectProcessOwnershipsAdapter,
  projectTypeAdapter,
} from './adapters';
import {
  clearFirescoreList,
  clearMonitorList,
  clearProjectNameList,
  clearScrollIntoLogId,
  getFirescoreList,
  getHistoryClientFeedback,
  getHistoryContext,
  getProject,
  // getProjectBios,
  getProjectClientType,
  getProjectCountries,
  getProjectCurrencies,
  getProjectDesign,
  getProjectFirescore,
  getProjectIndustry,
  getProjectMethodology,
  getProjectMonitorList,
  getProjectNameList,
  getProjectNpsHistory,
  getProjectOwnership,
  getProjectProcessOwnerships,
  getProjectType,
  patchProject,
  patchProjectFirescore,
  patchProjectNps,
  postProject,
  setExpandLogComments,
  setProjectObserve,
  setScrollIntoLogId,
} from './actions';
import {
  ProjectState,
} from '../interfaces';

const initialState: ProjectState = {
  bios: projectBioAdapter.getInitialState(),
  clientType: projectClientTypeAdapter.getInitialState(),
  countries: projectCountryAdapter.getInitialState(),
  currencies: projectCurrencyAdapter.getInitialState(),
  design: projectDesignAdapter.getInitialState(),
  firescoreHistory: projectFirescoreHistoryAdapter.getInitialState({
    status: RequestStatus.IDLE,
  }),
  firescores: projectFirescoreAdapter.getInitialState({
    status: RequestStatus.IDLE,
  }),
  historyClientFeedback: historyClientFeedbackAdapter.getInitialState({
    count: 0,
    next: null,
    prev: null,
    status: RequestStatus.IDLE,
  }),
  historyContext: historyContextAdapter.getInitialState({
    count: 0,
    next: null,
    prev: null,
    status: RequestStatus.IDLE,
  }),
  industry: projectIndustryAdapter.getInitialState(),
  methodology: projectMethodologyAdapter.getInitialState(),
  monitors: projectMonitorAdapter.getInitialState({
    count: 0,
    next: null,
    prev: null,
    status: RequestStatus.IDLE,
  }),
  names: projectNameAdapter.getInitialState({
    status: RequestStatus.IDLE,
  }),
  npsHistory: projectNpsHistoryAdapter.getInitialState({
    count: 0,
    data: [],
    next: null,
    prev: null,
    status: RequestStatus.IDLE,
  }),
  ownership: projectOwnershipAdapter.getInitialState(),
  processOwnerships: projectManagersProjectAdapter.getInitialState(),
  project: {
    data: null,
    isSaving: RequestStatus.IDLE,
    status: RequestStatus.IDLE,
  },
  transitionData: {
    expandLogComments: false,
    scrollIntoLogId: null,
  },
  type: projectTypeAdapter.getInitialState(),
};

export const projectReducer = createReducer(
  initialState,
  (builder: ActionReducerMapBuilder<ProjectState>) => {
    builder
      .addCase(getProjectNameList.pending, ({ names }) => {
        names.status = RequestStatus.LOADING;
      })
      .addCase(getProjectNameList.fulfilled, ({ names }, action) => {
        projectNameAdapter.setAll(names, action.payload);
        names.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getProjectNameList.rejected, ({ names }) => {
        names.status = RequestStatus.FAILED;
      })
      .addCase(clearProjectNameList, ({ names }) => {
        projectNameAdapter.removeAll(names);
      })
      .addCase(getProjectMonitorList.pending, ({ monitors }) => {
        monitors.status = RequestStatus.LOADING;
      })
      .addCase(getProjectMonitorList.fulfilled, ({ monitors }, action) => {
        const {
          count,
          next,
          prev,
          results,
        } = action.payload;

        projectMonitorAdapter.setAll(monitors, results);
        monitors.status = RequestStatus.SUCCEEDED;

        monitors.count = count;
        monitors.prev = prev;
        monitors.next = next;
      })
      .addCase(getProjectMonitorList.rejected, ({ monitors }) => {
        monitors.status = RequestStatus.FAILED;
      })
      .addCase(clearMonitorList, ({ monitors }) => {
        monitors.status = RequestStatus.IDLE;
        projectNameAdapter.removeAll(monitors);
      })
      .addCase(clearScrollIntoLogId, ({ transitionData }) => {
        transitionData.scrollIntoLogId = null;
      })
      .addCase(getProject.pending, ({ project }) => {
        project.status = RequestStatus.LOADING;
      })
      .addCase(getProject.fulfilled, ({ project }, { payload }) => {
        project.status = RequestStatus.SUCCEEDED;
        project.data = payload;
      })
      .addCase(getProject.rejected, ({ project }) => {
        project.status = RequestStatus.FAILED;
      })
      .addCase(setScrollIntoLogId, ({ transitionData }, { payload }) => {
        transitionData.scrollIntoLogId = payload;
      })
      .addCase(setExpandLogComments, ({ transitionData }, { payload }) => {
        transitionData.expandLogComments = payload;
      })
      .addCase(setProjectObserve.pending, ({ project }) => {
        project.status = RequestStatus.LOADING;
      })
      .addCase(setProjectObserve.fulfilled, ({ project }, { payload }) => {
        project.status = RequestStatus.SUCCEEDED;
        project.data = payload;
      })
      .addCase(setProjectObserve.rejected, ({ project }) => {
        project.status = RequestStatus.FAILED;
      })
      .addCase(getProjectFirescore.pending, ({ firescoreHistory }) => {
        firescoreHistory.status = RequestStatus.LOADING;
      })
      .addCase(getProjectFirescore.fulfilled, ({ firescoreHistory }, { payload }) => {
        firescoreHistory.status = RequestStatus.SUCCEEDED;
        projectFirescoreHistoryAdapter.setAll(firescoreHistory, payload);
      })
      .addCase(getProjectFirescore.rejected, ({ firescoreHistory }, { payload }) => {
        firescoreHistory.status = RequestStatus.FAILED;
      })
      .addCase(clearFirescoreList, ({ firescoreHistory }) => {
        projectFirescoreHistoryAdapter.removeAll(firescoreHistory);
        firescoreHistory.status = RequestStatus.IDLE;
      })
      .addCase(getFirescoreList.pending, ({ firescores }) => {
        firescores.status = RequestStatus.LOADING;
      })
      .addCase(getFirescoreList.fulfilled, ({ firescores }, { payload }) => {
        firescores.status = RequestStatus.SUCCEEDED;
        projectFirescoreAdapter.setAll(firescores, payload);
      })
      .addCase(getFirescoreList.rejected, ({ firescores }) => {
        firescores.status = RequestStatus.FAILED;
      })
      .addCase(getProjectCountries.fulfilled, ({ countries }, { payload }) => {
        projectCountryAdapter.setAll(countries, payload);
      })
      /* .addCase(getProjectBios.fulfilled, ({ bios }, { payload }) => {
        projectBioAdapter.setAll(bios, payload);
      }) */
      .addCase(getProjectIndustry.fulfilled, ({ industry }, { payload }) => {
        projectIndustryAdapter.setAll(industry, payload);
      })
      .addCase(getProjectOwnership.fulfilled, ({ ownership }, { payload }) => {
        projectOwnershipAdapter.setAll(ownership, payload);
      })
      .addCase(getProjectMethodology.fulfilled, ({ methodology }, { payload }) => {
        projectMethodologyAdapter.setAll(methodology, payload);
      })
      .addCase(getProjectType.fulfilled, ({ type }, { payload }) => {
        projectTypeAdapter.setAll(type, payload);
      })
      .addCase(getProjectClientType.fulfilled, ({ clientType }, { payload }) => {
        projectTypeAdapter.setAll(clientType, payload);
      })
      .addCase(getProjectDesign.fulfilled, ({ design }, { payload }) => {
        projectDesignAdapter.setAll(design, payload);
      })
      .addCase(getProjectProcessOwnerships.fulfilled, ({ processOwnerships }, { payload }) => {
        projectProcessOwnershipsAdapter.setAll(processOwnerships, payload);
      })
      .addCase(getProjectCurrencies.fulfilled, ({ currencies }, { payload }) => {
        projectCurrencyAdapter.setAll(currencies, payload);
      })
      .addCase(getHistoryContext.pending, ({ historyContext }) => {
        historyContext.status = RequestStatus.LOADING;
      })
      .addCase(getHistoryContext.fulfilled, ({ historyContext }, action) => {
        const {
          count,
          next,
          prev,
          results,
        } = action.payload;

        historyContextAdapter.setAll(historyContext, results);
        historyContext.status = RequestStatus.SUCCEEDED;

        historyContext.count = count;
        historyContext.prev = prev;
        historyContext.next = next;
      })
      .addCase(getHistoryContext.rejected, ({ historyContext }) => {
        historyContext.status = RequestStatus.FAILED;
      })
      .addCase(patchProjectNps.pending, ({ project }) => {
        project.isSaving = RequestStatus.LOADING;
      })
      .addCase(patchProjectNps.rejected, ({ project }) => {
        project.isSaving = RequestStatus.FAILED;
      })
      .addCase(patchProjectNps.fulfilled, ({ project }, { payload }) => {
        if (project.data) {
          project.isSaving = RequestStatus.SUCCEEDED;
          project.data.npsScore = payload;
        }
      })
      .addCase(getProjectNpsHistory.pending, ({ npsHistory }) => {
        npsHistory.status = RequestStatus.LOADING;
      })
      .addCase(getProjectNpsHistory.fulfilled, ({ npsHistory }, action) => {
        const {
          count,
          next,
          prev,
          results,
        } = action.payload;

        npsHistory.status = RequestStatus.SUCCEEDED;
        npsHistory.data = results;

        npsHistory.count = count;
        npsHistory.prev = prev;
        npsHistory.next = next;
      })
      .addCase(getProjectNpsHistory.rejected, ({ npsHistory }, { payload }) => {
        npsHistory.status = RequestStatus.FAILED;
      })
      .addCase(getHistoryClientFeedback.pending, ({ historyClientFeedback }) => {
        historyClientFeedback.status = RequestStatus.LOADING;
      })
      .addCase(getHistoryClientFeedback.fulfilled, ({ historyClientFeedback }, action) => {
        const {
          count,
          next,
          prev,
          results,
        } = action.payload;

        historyContextAdapter.setAll(historyClientFeedback, results);
        historyClientFeedback.status = RequestStatus.SUCCEEDED;

        historyClientFeedback.count = count;
        historyClientFeedback.prev = prev;
        historyClientFeedback.next = next;
      })
      .addCase(getHistoryClientFeedback.rejected, ({ historyClientFeedback }) => {
        historyClientFeedback.status = RequestStatus.FAILED;
      })
      .addMatcher(isAnyOf(postProject.pending, patchProject.pending, patchProjectFirescore.pending),
        ({ project }) => {
          project.isSaving = RequestStatus.LOADING;
        })
      .addMatcher(
        isAnyOf(postProject.fulfilled, patchProject.fulfilled, patchProjectFirescore.fulfilled),
        ({ project }, { payload }) => {
          project.isSaving = RequestStatus.SUCCEEDED;
          project.data = payload;
        }
      )
      .addMatcher(isAnyOf(
        postProject.rejected,
        patchProject.rejected,
        patchProjectFirescore.rejected
      ),
      ({ project }) => {
        project.isSaving = RequestStatus.FAILED;
      });
  }
);
