import { request } from '@/utils/request';

import {
  Role, SimpleEmployee,
} from './interfaces';

export const api = {
  getEmployeesList: () => request.get<Array<SimpleEmployee>>('v1/employees/'),
  getRolesList: () => request.get<Array<Role>>('v1/member_roles/'),
};
