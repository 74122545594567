import {
  createReducer,
} from '@reduxjs/toolkit';

import { RequestStatus } from '@/core/interfaces/common';

import { ProjectManagerState } from '../interfaces/state';
import {
  projectManagersAdapter, projectManagersProjectAdapter,
} from './adapters';
import {
  clearProjectManagersList,
  clearProjectManagersProjectsList,
  getProjectManagersList,
  getProjectsList,
  setActiveProjectManager,
} from './actions';

const initialState: ProjectManagerState = {
  list: projectManagersAdapter.getInitialState({
    active: null,
    status: RequestStatus.IDLE,
  }),
  projects: projectManagersProjectAdapter.getInitialState({
    status: RequestStatus.IDLE,
  }),
};

export const projectManagerReducer = createReducer(
  initialState,
  builder => {
    builder
      .addCase(getProjectManagersList.pending, ({ list }) => {
        list.status = RequestStatus.LOADING;
      })
      .addCase(getProjectManagersList.fulfilled, ({ list }, action) => {
        projectManagersAdapter.setAll(list, action.payload);
        list.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getProjectManagersList.rejected, ({ list }) => {
        list.status = RequestStatus.FAILED;
      })
      .addCase(clearProjectManagersList, ({ list }) => {
        projectManagersAdapter.removeAll(list);
        list.status = RequestStatus.IDLE;
      })
      .addCase(setActiveProjectManager, ({ list }, action) => {
        list.active = action.payload;
      })
      .addCase(getProjectsList.pending, ({ projects }) => {
        projects.status = RequestStatus.LOADING;
      })
      .addCase(getProjectsList.fulfilled, ({ projects }, { payload }) => {
        projectManagersProjectAdapter.setAll(projects, payload);
        projects.status = RequestStatus.SUCCEEDED;
      })
      .addCase(getProjectsList.rejected, ({ projects }) => {
        projects.status = RequestStatus.FAILED;
      })
      .addCase(clearProjectManagersProjectsList, ({ projects }) => {
        projectManagersProjectAdapter.removeAll(projects);
        projects.status = RequestStatus.IDLE;
      });
  }
);
