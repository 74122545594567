import {
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { api } from '@/features/Integration/api';
import {
  SlackChannel,
  SlackChannelPayload,
} from '@/features/Integration/interfaces';

import { getActionPrefix } from '@/utils/helpers';

const actionPrefix = getActionPrefix('integration');

export const getSlackChannels = createAsyncThunk<
  Array<SlackChannel>,
  SlackChannelPayload
>(`${actionPrefix}/get-slack-channel-list`, async (params: SlackChannelPayload, { rejectWithValue }) => {
  try {
    const { data } = await api.getSlackChannels(params);

    return data;
  } catch ({ response: data }) {
    return rejectWithValue(data);
  }
});

export const clearSlackChannels = createAction(`${actionPrefix}/clear-list`);
