import { HYDRATE } from 'next-redux-wrapper';
import {
  diff,
  DiffPatcher,
  patch,
} from 'jsondiffpatch';
import {
  AnyAction,
  createReducer,
} from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

const initialState = {} as RootState;

export const hydrateReducer = createReducer(
  initialState,
  builder => builder.addCase(HYDRATE, (state, action: AnyAction) => {
    const jsondiffpatch = new DiffPatcher();
    let stateDiff = diff(action.payload, state);
    let clonedPayload = jsondiffpatch.clone(action.payload);

    if (!state?.config?.appInitialized) {
      stateDiff = diff(state, action.payload);
      clonedPayload = jsondiffpatch.clone(state);
    }

    if (stateDiff) {
      patch(clonedPayload, stateDiff);
    }

    return {
      ...state,
      ...clonedPayload,
    };
  })
);
