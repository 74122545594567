import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import { notificationAdapter } from './adapters';
import { NotificationState } from '../interfaces';

export const {
  selectAll: selectAllNotifications,
} =
  notificationAdapter.getSelectors<RootState>(state => state.notification.list);

const featureStateSelector = (state: RootState): NotificationState => state.notification;

export const getNotificationsList = createSelector(
  featureStateSelector,
  state => state.list.entities
);

export const getTotalNotifications = createSelector(
  featureStateSelector,
  state => state.list.count
);

export const getTotalUnreadNotifications = createSelector(
  featureStateSelector,
  state => state.list.countUnread
);

export const getNotificationsListStatus = createSelector(
  featureStateSelector,
  state => state.list.status
);

export const getNextPage = createSelector(
  featureStateSelector,
  state => state.list.next
);

export const getPreviousPage = createSelector(
  featureStateSelector,
  state => state.list.prev
);
