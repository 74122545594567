import {
  PaginatedResponse,
} from '@/core/interfaces/common';

import { request } from '@/utils/request';

import {
  NotificationParams,
  SimpleNotification,
} from './interfaces';

type PaginatedNotificationResponse<T> = PaginatedResponse<T> & {
  countUnread: number;
}

export const api = {
  deleteNotification: (id: number) => request.delete(`v1/notifications/${id}/`),
  getNotifications: (params: Partial<NotificationParams>) => request.get<PaginatedNotificationResponse<SimpleNotification>>('v1/notifications/', { params }),
  setAsRead: (id: number) => request.put<SimpleNotification>(`v1/notifications/${id}/`, { isRead: true }),
};
