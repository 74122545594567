import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/core/interfaces/store';

import { DashboardState } from '../interfaces';

const featureStateSelector = (state: RootState): DashboardState => state.dashboard;

export const getRowsPerPage = createSelector(
  featureStateSelector,
  state => state.rowsPerPage
);

export const getPage = createSelector(
  featureStateSelector,
  state => state.page
);

export const getOrdering = createSelector(
  featureStateSelector,
  state => state.ordering
);

export const getSortBy = createSelector(
  featureStateSelector,
  state => state.sortBy
);

export const getSortOrder = createSelector(
  featureStateSelector,
  state => state.sortOrder
);
