import {
  PaginatedResponse, PaginationParams,
} from '@/core/interfaces/common';

import { request } from '@/utils/request';

import { SimpleMention } from './interfaces';

export const api = {
  getMentions: (params: Partial<PaginationParams>) => request.get<PaginatedResponse<SimpleMention>>('v1/mentions/', { params }),
};
